import React from 'react'
import Link from '../components/CustomLink'
import Layout from '../components/Layout'
import { ArrowBackIcon } from '../img/icons'

const NotFoundPage = () => (
  <Layout>
    <section className="p-6 md:p-4 md:pt-8 text-center">
      <h1 className="text-2xl font-bold mt-6">404</h1>
      <p className="font-bold mb-5">Looks like this page does not exist...</p>
      <Link to="/">
        <ArrowBackIcon />
        <span className="ml-1">Home</span>
      </Link>
    </section>
  </Layout>
)

export default NotFoundPage
